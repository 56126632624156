<template>
  <b-table
    ref="table"
    hover
    :fields="selectedTableFields()"
    :items="fetchTable"
  >
    <template #cell(id)="{ item }">
      <a
        :href="getEnvUrl(item.id, item.env)"
        target="_blank"
      >
        {{ item.name }}
      </a>
    </template>
    <template #cell(actions)="{ item }">
      <div class="buttons">
        <b-button
          variant="success"
          @click.stop="startNewDynTest(item)"
        >
          Запуск теста
        </b-button>
        <b-button
          variant="secondary"
          @click.stop="openReport(item)"
        >
          Результаты
        </b-button>
        <a
          :href="`https://upstars.atlassian.net/browse/${ item.id.toUpperCase() }`"
          target="_blank"
        >
          <b-button
            variant="primary"
            @click.stop="showModal(item)"
          >
            Jira
          </b-button>
        </a>
      </div>
    </template>
  </b-table>
</template>

<script>
import {
  BButton,
  BTable,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'DynamicTable',
  components: {
    BButton,
    BTable,
  },

  props: {
    project: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapGetters('app', {
      apiAddr: 'apiAddr',
    }),
  },

  watch: {
    project: {
      handler() {
        this.$refs.table.refresh()
      },
    },
  },

  methods: {
    selectedTableFields() {
      return [
        {
          key: 'id', label: 'Окружения', sortable: false,
        },
        {
          key: 'actions', label: 'Действия',
        },
      ]
    },

    getEnvUrl(key, env) {
      if (this.project === 'alpa') {
        return `https://mock-${key}.rocketplay.wlabel.site`
      }

      if (env) {
        return `https://mock-${key}-thor.${env}.wlabel.site`
      }

      return `https://mock-${key}-thor.development.wlabel.site`
    },

    startNewDynTest(item) {
      this.loading = true
      fetch(`${this.apiAddr}${this.project}/api/start?service=${item.origin}&folder=${item.name}`).then(() => {
        this.loading = false
        this.$refs.table.refresh()
      })
    },

    fetchTable(_, callback) {
      const regex = /(un-\d+|alpa-\d+|fp-\d+|sre-\d+|revert-[0-9a-f]+)/
      fetch(`${this.apiAddr}${this.project}/api/app-list`).then(res => res.json()).then(res => {
        const envs = ['development', 'staging']

        callback(
          res
            .filter(folder => Boolean(folder.match(regex)))
            .map(folder => {
              const env = envs.find(e => folder.includes(e))
              return {
                id: folder.match(regex)[0],
                name: folder.replace('-mock', ''),
                env,
                origin: folder,
              }
            }),
        )
      })
    },

    openReport(row) {
      this.$router.push({
        name: `report-page-${this.project}`,
        params: {
          test: row.origin,
        },
      })
    },
  },
}
</script>

<style scoped>
.buttons {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
}
</style>
